:root {
    /* Breakpoints */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    /* Flex paddings  and margins*/
    --padding: 1rem;
    --vertical-padding: 1rem;
    --margin: .5rem;
    --vertical-margin: .5rem;
    /* Fixed header */
    --fixed-header-height: 10vh;
    /* Slick */
    --slick-slider-height: 45vh;
    /* Colors */
    --text-color: #313638;
    --body-bg: #fff;
    --main-color: #fcfcfb;
    --accent-color: #cdfd70;
    --effect-color: #333;
    --header-text-color: #333;
    --header-bg-color: var(--main-color);
    --footer-bg-color: var(--main-color);
    --footer-text-color: var(--text-color);
    --menu-bg-color: inherit;
    --menu-text-color: #ccc;
    --menu-text-hover-color: #fff;
    --menu-text-bg-color: inherit;
    --menu-text-bg-hover-color: #333;
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: NewsCycle, sans-serif;
    --alternative-font-family: var(--base-font-family);
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/baguetteBox.min.css";
@import "/fontmodules/NewsCycle.css";

/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

.boka-tid iframe {
    width: 800px;
}


html {
    height: 100%;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-weight: 400;
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    background: var(--body-bg);
    color: var(--text-color);
}
p.red {
    color: #f00;
}
header {
    background: var(--header-bg-color);
    color: var(--header-text-color);
    box-shadow: 0 3px 3px rgba(0,0,0,0.3);
}

header a {
    text-decoration: none;
}

.logo {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor))rem;
}

.logo a {
    padding: 1rem 2rem;
}

.logo span:last-of-type {
    display: block;
    font-size: calc(1 * 1)rem;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}
.readmore {
    padding: .1em .5em;
    background: #333;
    color: #fff;
    border-radius: 6px;
    font-size: 1rem;
    max-width: 67px;
    margin: .5em 0;
}
img {
    max-width: 100%;
    height: auto;
    display: block;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.homepage article h1 {
    column-span: all;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.homepage article p {
    display: inline-block;
}

.homepage article img {
     margin-bottom: 1rem;
}

figure {
    margin: 0;
}
        
/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/
h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: 1rem;
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

nav {
    background: var(--effect-color);
    margin-top: 2rem;
}

.menu.menu li {
    margin: 0;
}

.menu.menu li a {
    padding: .5rem .8rem;
    color: var(--menu-text-color);
    background: var(--menu-text-bg-color);
    text-transform: uppercase;
    transition: 0.5s ease-in 0s;
}

.menu.menu li.active a, .menu.menu li a:hover {
    background: color(var(--menu-text-bg-hover-color));
    color: var(--menu-text-hover-color);
}

/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/
/*slick slider*/
.slick-wrapper {min-height:60vh; position:relative; flex-wrap:wrap; }
.slick-slider, .slick-substitute > div, .slick-slide {min-height:60vh; margin:0; }
.slick-slider, .slick-substitute, .slick-substitute > div { width:100%; height:100%; top:0; left:0; z-index:0;}
.slick-slide, .slick-substitute > div {background-size:cover; background-position: center center; display:flex !important;}   
.slick {visibility: hidden;} .slick-initialized {visibility: visible;} .slick-list, .slick-track {height:100%;}
.slick-slider, .slick-substitute > div {position:absolute; width:100%; height:100%;  top:0; left:0; z-index:-1; }

/*starttext*/ 
.starttext {padding: 30px 0; width:100%; text-align:center; }

/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
.slick-wrapper, .slick-slider, .slick-substitute > div, .slick-slide {min-height:55vw;}
}

/*--------------------------------------------------------------
BOXAR
--------------------------------------------------------------*/
.boxwrapper {
    min-height: 30vh;
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    z-index: 3;
    font-weight: 400;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .95s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform .95s ease-in 0s;
}

.zoombox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .95s ease-in 0s;
    z-index: 2;
}

.zoombox .boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .95s ease-out 0s;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .95s ease-out 0s;
}

/*-------------------------------------------------------------
Sidspecifik style
--------------------------------------------------------------*/

.slogan {
    font-size: calc(1.5 * 1)rem;
    /*text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    color: #fff;*/
}

.intro {
    background: #ffffff;
    text-align: center;
    font-size: calc(1.5 * 1)rem;
    padding: 2rem; 
    position: relative;

}

.intro p {
 
        color: white !important;
}

.intro:before,
.intro:after {
    content: ''; 
    position: absolute;
    top: -100px;
}

.intro:before {
    left: 80%;
    width: 170px;
    height: 112px;
    background: url('/theme/intendit/graphics/airplane.png') no-repeat;
}

.aktuellt {
    background: repeating-linear-gradient(
    45deg,
    #fff,
    #eee 1px,
    #fff 7px,
    #fff 7px
);
    padding: 2rem 0;
}

.aktuellt h3 {
    font-size: 2.3rem;
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.logobanner {
}

.logobanner img  {
    padding: var(--padding);
}

.om-oss-1 figure img {
 /*   max-width: 161px; */
    padding-top: 100px;
} 

/*-------------------------------------------------------------
FOOTER
-------------------------------------------------------------*/
.footer-wrapper {
    background: #eee;
    box-shadow:inset 0 10px 10px 0 rgba(181,181,181,0.2);
    min-height: 500px;
}

footer {
    padding: 1rem 0;
    color: var(--footer-text-color);
    height: 40vh;
    

}

/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
   /* IE fix */ min-height: 1%;
}

header, footer, .footer-wrapper {
    flex: none;
}

/*--------------------------------------------------------------
Forms
--------------------------------------------------------------*/

.boltform *, .boltform input[type] {
    border-radius: 0px;
}

.boltform {
    color: var(--header-text-color);
    padding: 0;
}

.boltforms-row label {
    margin-bottom: 0;
}

.boltform input, .boltform textarea {
    width: 100%;
}

.boltform button {
    background: var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
    border: none;
    margin: 0;
}

.boltform button:hover {
    background: color(var(--menu-text-bg-hover-color) lightness(65%));
    color: var(--menu-text-hover-color);
}

.boltform .boltforms-row label.required:after {
    content: " *";
    color: var(--menu-text-bg-hover-color);
}

.intenditfooter {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.4);
    margin: 0 .1rem 0 0;
    text-align: right;
}

.intenditfooter a {
    text-decoration: none;
}
.startRed h2 {
    color:red !important;
}
/*--------------------------------------------------------------
Maps
--------------------------------------------------------------*/

.map-canvas {
    height: 35vh;
}

.map-canvas img {
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
    

    .intro:before {
        display: none;
    }
    

    
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    
    .om-oss-1 figure img {
    padding-top: 0px;
}
        .intro:before {
        background: none;
    }

}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
    
        header {
        margin-top: 0px;
    }
    
    .homepage article {
        column-count: 1;
    }
}

@media (max-width: var(--breakpoint-menu)) {
    
    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
    }
    
    
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border: 2px solid var(--accent-color) !important;
    }
    
        .intro:before, {
        display: none;
    }
    
}
